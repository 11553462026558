.header {
  padding:0 0 45px 0;
}

.left-menu {
  font-size:40px;
}

.right-menu {
}

.menu-item {
text-align: center;
}

.headr-btn-txt{
  font-size: 20px;
      line-height: 28px;
      color: #fff;
  }
  
@media (max-width: 576px){
.headr-btn-txt{
  font-size: 15px;
}
}