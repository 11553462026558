
.body{
height: 85vh;
overflow: hidden;
  background-color:#00B1FF;
}
.tagline{
 margin-bottom: 4.5vh;
} 
.hw-font{
  font-family: 'Caveat';
}
.lp-1{
    background-color:#00B1FF;
    /* padding: min(10%,132px) 7.8%; */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
}
.underLine2 {
    display: inline-block;
    position: relative;
  }

.cld-image{
  position: absolute;
    bottom: 0;
    right: 0;
    width: 23%;
    min-width: 200px;
}
.underLine2:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-in-out;
  }
  .underLine2:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
.header .right-menu{  
  a:hover {
    transform: scale(1.02);
  }
  div > a {
    margin-right: 2vw;
    font-weight: bold;
  }
  div:last-child > a {
    margin-right: 0;
  }
}

  .image-container {
    text-align: center;
  }
  
  .centered-image {
    max-width: 96%;
    max-height: 96%;
    object-fit: contain;
  }
  .horizontal-split-container {
    display: flex;
    color: #00B1FF;

  }
  .left-half,
.right-half {
  width: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}
.storapic{
  max-width: 70%;
    max-height: 90%;
    object-fit: contain;
}
.image-container,
.top-half,
.bottom-half {
  text-align: center;
  
}
.stora-head{
    font-size: 32px;
    line-height: 47px;
    color: #fff;
    font-weight: 600;
    display: flex;
    text-align: left;


}
.tag-image {
  max-width: 90%;
  max-height: 96%;
  object-fit: contain;
}
.stora-btn {
    margin-left: 2vw;
   
  }
.header>a{
    width:35%;
    max-width: 161px;
}
.lp-link{
    padding:7px 22px;
    background: #FFFFFF;
border-radius: 20px;
font-size: 16px;
line-height: 22px;
color: #00B1FF; 
text-decoration: none;
border:1px solid #fff;
}
.lp-link:not(:disabled):hover,.lp-link:not(:disabled):active{
    color:#fff;
    background: transparent;
}
.lp-link-secondary{
    color:#262168;
    background: transparent;
    border:none;
    
}
.lp-1 h1{
    font-size: 32px;
line-height: 40px;
color: #141938;
font-weight: 600;
margin-bottom: 13px;
height: 100vh;
}
.title-highlight{
    color:#F65D65;
}
.lp-1-w-1{
    margin:min(18%,126px) 0;
}
.lp-1-w-1 p{
    font-size: 16px;
    line-height: 24px;    
    color: rgba(20, 25, 56, 0.8);   
    max-width: 420px;
}
.lp-1-w-2{
    border: 1px solid rgba(20, 25, 56, 0.2);
border-radius: 100px;
overflow: hidden;
max-width: 347px;
margin-top: 30px;
flex:0 0 347px;
}
.lp-1-w-2 input{
    border: none;
    outline:none;
    padding: 13px 0px 13px 21px;
    font-size: 12px;
line-height: 22px;
/* or 183% */

letter-spacing: -0.01em;

color: rgba(20, 25, 56, 0.8);
width:230px;
}
.lp-1-w-2 button{
border-radius: 0px;
flex:1;
border-width: 0px 0px 0px 1px !important;
}

.lp-1-footer{
  position: absolute;
  bottom: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 27px;
  color: white;
  z-index: 1;
  left: 9px;
}
@media only screen and (min-width:576px){
  .cbody{
    padding-top: calc(25vh - 150px);
  }
  .lp-1-footer{
    font-size: 24px;
    left: 4.2%;
    bottom: 3.7vh;  
  }  
  .tagline{
    margin-bottom: 7.3vh;
   } 
}