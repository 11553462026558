html{
  font-size: 16px;
}
.App {
  position: relative;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #00B1FF;
  color: #fff;
  padding:45px 4.2% 0;
}
.text-blue{
  color:#00B1FF;
}
body a{
  text-decoration: none;
}
.fs-40{
  font-size: 40px;
}
.fs-36{
  font-size: 36px;
  line-height: 1;
}
.fs-48{
  font-size:48px;
  line-height: 1;
}
.fs-32{
  font-size:2rem;
}
.fs-24{
  font-size:24px;
  line-height: 1.3;
}
.lh{
  line-height: 1;
}
.lh-2{
  line-height: 1.2
}
.lh-3{
  line-height: 1.3;
}
.lh-90{
line-height: .9;
}
.text-right{
  text-align: right;
}
.cursor-pointer{
  cursor: pointer;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #fff;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
  padding-left:4px;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  width: 28px;
  height: 22px;
  border-radius: 50%;
  transition: transform 0.3s ease;
  background: #00B1FF;
  top:3.5px;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(85%);
  background-color: #00B1FF;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #fff;
}
.plan{
height: 414px;
background-image:url(./assets/images/bcloud.png);
background-size: 100% 100%;
color: #00B1FF;
margin: 40px 0 0;
}
.plan > div:first-child{
  margin-top:15%; 
}
.plan .lp-link{
margin:5% 10% 0 0;
font-size: 36px;
line-height: 1.3;
}
.contact{
  padding:5px 5%;
  background: #fff;
  color:#00B1FF;
  border-radius: 30px;
  line-height: 1.3;
}
.toaster{
  z-index: 1056;
  position: fixed;
  right: 10px;
  top: 10px;
  color: #fff;
  padding: 20px;
  width: 50%;
  max-width: 250px;
  opacity: 0;
  -webkit-animation: 3s fade-in-fade-out linear;
  animation: 3s fade-in-fade-out linear;
  border-radius: 4px;
  }
  @-webkit-keyframes fade-in-fade-out {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
            
  @keyframes fade-in-fade-out  {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }

.toaster-success{
background-color: #5cb85c;
}
.toaster-error{
background-color: #d9534f;
}
.invalid-feedback{
  margin-top: 0px;
  line-height: .5;
  text-align: left;
}
body .modal-content{
  background-color:#00B1FF;
  color:#fff;
}
body .modal-header{
  border:none;
}
body .modal-header .btn-close{
  font-size:16px;
  opacity:1;
}
body .modal-body button{
  background: #FFFFFF;
  color: #00B1FF;
}
body .modal-body button:not(.copy-link):hover{
  background:transparent;
  color: #fff;
  border: 1px solid #fff;
}
.qr-image{
  width:330px;
  height: 330px;
}
.copy-link{
  background: transparent;
  border:none;
  color:#00B1FF;
  font-size:36px;
  text-transform: none;
}
.contact a{
  color:#000;
  text-decoration: none;
}
.loader{
  width:52px;
  height:52px;
  border:7px solid #E7E6EA;
  border-radius:50%;
  border-top-color:#141938;
  animation:revolve 2s infinite linear;
  }
  @keyframes revolve {
    100% {transform: rotate(360deg)}
  }
  .loader-title{
    font-size: 11px;
line-height: 17px;
color: #403759;
  }
  .menu{
    z-index: 1;
  }
  .menu button{
    background: #fff;
    color:#00B1FF;
    border-radius:0px;
  }
  .plain-button{
    border:none;
    background: none;
  }
  .justify-content-between {
    -webkit-justify-content: space-between!important;
}
.abs-v-centered{
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.plain-link{
  color:inherit;
}
.w-80{
  width:80% !important;
}
.link-button{
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border:none;
  background: transparent;
}
.disabled{
  opacity: 0.5;
}
  @media only screen and (max-width: 576px) {
    html{
      font-size: 12px;
    }
    .fs-36,.fs-24{
      font-size: 16px;
    }
    .plan{
      height: 125px;
      width:176px;
      margin:15px 0;
    }
    .plan .lp-link{
      font-size: 14px;
      padding: 0px 5px;
      }
      .toggle-switch {
        width: 40px;
        height: 17px;
      }
      .toggle-switch .switch::before {
        width: 19px;
        height: 13px;
        top:1.8px;
      }
      .toggle-switch .switch {
        padding-left: 2.5px;
    }
  }