
  .icon-img{
  }

  .text-header{
    font-size: 2rem;
  }
  .space-header{
    font-size: 20px;
  }
  .progress-container{
   position: relative;
   width: 100%;
   height: 11px;
   border:1px solid #fff;
  }
  .progress-bar{
    position: absolute;
    right: 0;
    height: 100%;
    background: #fff;
  }

  .file-wrap{
    width:200px;
  }
  .file-wrap > img,.hover-menu{
    width:130px;
    height: 143px;
  }
  .hover-menu{
    background-color: hsla(0,0%,89.8%,.8);
    display: none;
    right: 36px;
  }
  .file-wrap:hover .hover-menu{
    display: flex;
  }


  .img-heading{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    font-weight: bold;
    color: #00B1FF;
    z-index: 2;
    padding-left: 15%;
    padding-top: 10%;
  }
  .file-container{
      padding-bottom: 185px;
  }
  .menuBar{
}
.menuBar .menu-button{
  padding: 8px 6px;

background-color: #FFFFFF;
border-radius: 0px;
line-height: 16px;
}
.menuBar .menu-button:hover,.menuBar .menu-button:focus,.menuBar .menu-button.active{
  background-color: #F6F8FE;
}
.sort-menu{
  right:40px;
  top:48px;
  z-index: 2;
box-shadow: 0px 2px 3px rgba(16, 5, 47, 0.03), 0px 1px 5px rgba(16, 5, 47, 0.1);
overflow: hidden;
}
.sort-menu-item{
  padding: 8px;
background: white; 
}
.sort-button-toggle{
padding: 4px;
background: #F4F4F6;
border-radius: 4px;
}
.sort-button-toggle button{
border-radius: 4px;
font-size: 13px;
line-height: 16px;
text-transform: uppercase;
padding: 4px;
}
.sort-button-toggle button.active,.sort-button-toggle button:hover,.sort-button-toggle button:focus{
background: #DBDAE0;
}
.sort-select{
width:130px;
}
.filter-menu{
  right:90px;
  top:48px;
  z-index: 2;
background: #FFFFFF;
box-shadow: 0px 5px 14px rgba(16, 5, 47, 0.1);
}
.filter-menu .react-datepicker-wrapper{
width:150px;
}
.filter-menu .datetime .react-datepicker-popper{
  width:327px;
  }
.filter-menu .react-datepicker-wrapper input{
  width:100%;
  }
.filter-menu > div{
  padding: 10px;
  border-radius: 6px;
}
.filter-menu-button{
  text-align: left;
  font-size: 11px;
  padding: 4px 10px !important;
  min-width: 78px;
}
.filter-field-container{
  background-color: #F6F8FE;
}
.clear-filter-button{
  font-size: 8px;
  text-decoration: underline;
  position: absolute;
  top:0;
  right:0;
}
.menu-field {
  font-size: 12px;
  background-color: #F6F8FE;
  width: 100px;
  padding: 11px 0;
}
.menu-field:focus-visible{
outline: none;
}
.menu-field::placeholder{
  color:rgba(93, 85, 191, 0.8);
}
.filter-list{
  overflow-y: auto;
  overflow-x: hidden;
  min-width:150px;
}
.filter-list label{
  font-size: 11px;
}
.locked-icon{    
position: absolute;
top: 30%;
left: 50%;
transform: translate(-50%, -30%);
z-index: 3;
}
@media (max-width: 576px){
    .space-header,.img-heading{
      font-size: 14px;
    }
    .home .text-right .fs-32{
      font-size: 12px;
    }
    .home .text-right .fs-48{
      font-size: 16px;
    }
    .home .text-right .fs-24,.file-wrap{
      font-size: 10px;
    }
    .file-wrap{
      width:70px;
    }
    .hover-menu{
      right:8px;
    }
    .file-wrap > img,.hover-menu{
      width:55px;
      height: 62px;
    }
    .file-wrap button img{
      width:16px;
    }
    .locked-icon{
     width:32px;
    }
  }
