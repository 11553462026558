.upload-container{
  border: 8px dashed rgba(0, 0, 0, .1);
  min-height: 70vh;
  background-image:url(../../assets/images/bcloud.png);
  background-repeat: no-repeat;
  background-position: center;
  color: #00B1FF; 
  background-size:60%; 
}
.upload-container ~ div p{
  line-height: 66px;
  }
@media (max-width:1200px){
  .upload-container{
    background-size:100%; 
  }
}
@media (max-width:576px){
  .upload-container{
    min-height:42.6vh; 
    background-size:70%; 
  }
  .upload-container ~ div p{
    font-size: 15px;
    line-height: 35px;
  }
  .upload-container ~ div p.fw-light{
    font-size: 14px;
  }
  .footer-note{
    font-size: 10px;
  }
}
